import sanitizeHtml from "sanitize-html";

const tagsRegex = /<p>(.*?)<\/p>/g;
const textRegex = /<\/?[A-Za-z]>/g;

export const formatHTMLDescription = (description: any) => {
  let textCuted: any = [];
  let sanitizeText: any;
  let sanitizedText: string;
  let tags: string;
  let indexChosen: number;

  const transformTags = {
    a: "",
    div: "",
    figure: "",
    strong: "",
    span: "",
    em: "",
  };

  sanitizeText = sanitizeHtml(description, { transformTags });
  tags = sanitizeText.match(tagsRegex)[0];
  indexChosen = tags.length < 40 ? 1 : 0;
  sanitizedText = sanitizeText.match(tagsRegex)[indexChosen].replace(textRegex, "");
  textCuted.push(sanitizedText);
  return textCuted;
};
