import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const GrantLanding = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="w-full relative bg-black">
        <div className="bg-video absolute w-full h-full top-0 left-0 -z-10">
          <div className="bg-video-hp -z-20">
            <video className="image-cover" playsInline autoPlay muted loop>
              <source src="/galaxy.webm" type="video/webm" />
              <source src="/galaxy.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="pt-96 md:pt-150 container sc-up-mb relative z-[1]">
          <div className="flex items-start flex-col lg:flex-row relative overflow-visible">
            <div className="2xl:w-636 2xl:shrink-0 opacity-0 animate-from-bottom">
              <h1 className="heading-1 ml-0">
                <Trans>Grant Proposals</Trans>
              </h1>
              <p className="mt-20 text-menu-white font-normal md:text-20">
                <Trans>
                  Milkomeda delivers rollup technologies to leading Layer 1 ecosystems by offering the most popular
                  smart contracting language, Solidity, while enhancing inter-blockchain interoperability, user
                  experience, and developer traction all at the Layer 2 level.
                </Trans>
              </p>
              <div className="flex flex-col items-center md:flex-row mt-30 md:mt-40">
                <a href="/contact" rel="noopener" className="btn-big mb-30 md:mb-0 md:mr-32">
                  <Trans>Contact Us</Trans>
                </a>
              </div>
            </div>
          </div>
          <div className="space-custom"></div>
        </div>

        <div className="w-full -z-10">
          <img
            className="w-full"
            srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
            src="/images/oval_BG.webp"
            width="1600"
            height="235"
            alt="oval-top"
          />
        </div>

        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default GrantLanding;
