import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import type { AirtableData } from "../../graphql-types";
import { TabGroup, ProposalCard, NewsCard, FilterList, Select } from "@dcspark/grants-ui";
import { formatDate, formatDateLocaleString } from "../utils/date";
import { formatHTMLDescription } from "../utils/formatHtmlDescription";
import classNames from "classnames";

import CatalystIcon from "../../static/images/icons/grants/catalyst.inline.svg";
import AlgorandIcon from "../../static/images/icons/grants/algorand.inline.svg";
import UrbitIcon from "../../static/images/icons/grants/urbit.inline.svg";
import { Locales } from "../../locales/languages";
import { useI18next } from "gatsby-plugin-react-i18next";

const initialCategory = "All";
const initialRecent = { label: "Most Recent", value: "recent" };
const initialStatus = { label: "All", value: "all" };

type BlogPost = {
  guid: string;
  categories: string[];
  link: string;
  thumbnail: string;
  title: string;
  pubDate: string;
  description: string;
};

type Option = {
  label: string;
  value?: string;
  disabled?: boolean;
};
function NewsPage({ items }: { items: BlogPost[] }) {
  const [recent, setRecent] = React.useState<Option>(initialRecent);
  const grantsPosts = items.filter((item) => item.categories.includes("grant"));

  const filteredPosts = recent.value === "recent" ? grantsPosts : grantsPosts.reverse();

  return (
    <div className="mt-20 md:mt-0">
      <div className="relative z-10 flex items-center pb-30 mb-20 space-x-32">
        <Select
          className="min-w-[240px]"
          value={recent}
          hideLabel={false}
          label="Sort by:"
          onChange={setRecent}
          options={[
            { label: "Most Recent", value: "recent" },
            { label: "Oldest", value: "old" },
          ]}
        />
      </div>
      <div className="space-y-60">
        {filteredPosts.map(({ title, description, thumbnail, pubDate, link }, index) => (
          <NewsCard
            key={index}
            title={title}
            description={formatHTMLDescription(description)}
            imgUrl={thumbnail}
            date={formatDateLocaleString(pubDate)}
            url={link}
            linkText="Read More"
            className={classNames("news gap-40 space-x-0 py-16", index % 2 === 0 ? "reverse" : "")}
          />
        ))}
      </div>
    </div>
  );
}

function ProposalPage({
  items,
}: {
  items: {
    node: {
      data: AirtableData;
    };
  }[];
}) {
  const [category, setCategory] = React.useState(initialCategory);
  const [recent, setRecent] = React.useState<Option>(initialRecent);
  const [status, setStatus] = React.useState<Option>(initialStatus);

  const proposals = items.map(({ node }) => {
    const { data } = node;
    return data;
  });

  const CatalystLength = proposals.filter(
    (item) => item.Grant_origin === "Cardano Catalyst"
  ).length;
  const AlgorandLength = proposals.filter((item) => item.Grant_origin === "Algorand").length;

  const options = [
    {
      label: "All",
      value: "All",
      aditionalText: `${proposals.length} Proposals`,
    },
    {
      label: "Catalyst",
      value: "Cardano Catalyst",
      icon: <CatalystIcon className="w-18 h-18 md:w-30 md:h-30" />,
      aditionalText: `${CatalystLength} Proposals`,
    },
    {
      label: "Algorand",
      value: "Algorand",
      icon: <AlgorandIcon className="w-18 h-18 md:w-30 md:h-30" />,
      aditionalText: `${AlgorandLength} Proposals`,
    },
  ];

  const filteredProposalByCategoryAndStatus = proposals
    .filter((item) => {
      if (category === "All") {
        return true;
      }
      return item.Grant_origin === category;
    })
    .filter((item) => {
      if (status.value === "all") {
        return true;
      }
      return item.Status?.toLowerCase() === status.value;
    });

  const filteredProposalByNewest = [...filteredProposalByCategoryAndStatus].sort(
    (a, b) => formatDate(b.Started_on).valueOf() - formatDate(a.Started_on).valueOf()
  );
  const filteredProposalByLastModified = [...filteredProposalByCategoryAndStatus].sort(
    (a, b) => formatDate(b.Last_Modified).valueOf() - formatDate(a.Last_Modified).valueOf()
  );

  const filteredProposal =
    recent.value === "recent"
      ? filteredProposalByNewest
      : recent.value === "modified"
      ? filteredProposalByLastModified
      : filteredProposalByNewest.reverse();

  return (
    <div className="mt-20 md:mt-0">
      <FilterList
        groupLabel="filter"
        className="my-16 mb-40 max-w-[760px] mx-auto"
        value={category}
        // @ts-ignore
        onChange={setCategory}
        options={options}
      />
      <div className="flex flex-col md:py-30 md:pt-20 mb-35 md:mb-30 space-y-20 md:flex-row md:items-center md:space-y-0 md:space-x-32">
        <Select
          className="relative z-20 min-w-[240px]"
          value={recent}
          hideLabel={false}
          label="Sort by:"
          onChange={setRecent}
          options={[
            { label: "Most Recent", value: "recent" },
            { label: "Last Modified", value: "modified" },
            { label: "Oldest", value: "old" },
          ]}
        />
        <Select
          className="relative z-20 min-w-[240px]"
          value={status}
          label="Filter by Status:"
          hideLabel={false}
          onChange={setStatus}
          options={[
            { label: "All", value: "all" },
            { label: "Completed", value: "completed" },
            { label: "Ongoing", value: "ongoing" },
          ]}
        />
      </div>
      <div className="space-y-16">
        {filteredProposal.length ? (
          filteredProposal.map((proposal, index) => {
            return <ProposalCard key={index} theme="milkomeda" {...proposal} />;
          })
        ) : (
          <p className="text-center text-white">No proposals found</p>
        )}
      </div>
    </div>
  );
}

const GrantProposalsSection = () => {
  const { language } = useI18next();

  const data = useStaticQuery<{
    milkomedafoundationMedium: {
      items: {
        guid: string;
        categories: string[];
        link: string;
        thumbnail: string;
        title: string;
        pubDate: string;
        description: string;
      }[];
    };
    milkomedaJaMedium: {
      items: {
        guid: string;
        categories: string[];
        link: string;
        thumbnail: string;
        title: string;
        pubDate: string;
        description: string;
      }[];
    };
    allAirtable: {
      edges: {
        node: {
          data: AirtableData;
        };
      }[];
    };
  }>(graphql`
    query MyQuery {
      milkomedafoundationMedium {
        items {
          guid
          link
          pubDate(formatString: "")
          categories
          title
          thumbnail
          description
        }
      }
      milkomedaJaMedium {
        items {
          guid
          link
          pubDate(formatString: "")
          categories
          title
          thumbnail
          description
        }
      }
      allAirtable(
        sort: { order: DESC, fields: data___Started_on }
        filter: { data: { Show_on_website: { eq: true }, Beneficiary: { eq: "Milkomeda" } } }
      ) {
        edges {
          node {
            data {
              Beneficiary
              Category
              Completed_on
              Currency
              Fund
              Description
              USD_equivalent
              Grant_origin
              Last_Modified(formatString: "YYYY-MM-DD")
              Project_Lead
              Project_name
              Started_on
              Status_desc_link1
              Status
              Status_description
              Status_title
              origin_link
              Show_on_website
            }
          }
        }
      }
    }
  `);

  const { items: ENpublications } = data.milkomedafoundationMedium;
  const { items: JApublications } = data.milkomedaJaMedium;
  const publications = language === Locales.english ? ENpublications : JApublications;

  const blogs = publications?.sort(
    (a, b) => new Date(b.pubDate).valueOf() - new Date(a.pubDate).valueOf()
  );

  return (
    <section className="relative agenda-bg">
      <div className="relative container section-custom-top z-[0]">
        <TabGroup
          options={[
            {
              name: "News",
              component: <NewsPage items={blogs} />,
            },
            {
              name: "Proposals",
              component: <ProposalPage items={data.allAirtable?.edges} />,
            },
          ]}
        />
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0 z-[-1]">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GrantProposalsSection;
