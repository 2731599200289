export const formatDate = (date: string) => {
  return new Date(date?.replace(/-/g, "/"));
};

export const formatDateLocaleString = (date: string) => {
  return formatDate(date).toLocaleDateString("default", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
